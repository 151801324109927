<template>
  <div>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
    <b-modal
      v-model="showModalWebSite"
      scrollable
      :title="cvAddModalHeader"
      size="lg"
      :hide-footer="true"
      no-close-on-esc
    >
      <websiteAndLinks :propModuleId="userData.user_id" :propOpenedInModal="showWesiteAndLinkModal" @emitcloseWebsite_Links="closeWebsite_Links" :propSocialLinks = "propSocialLinks" />
    </b-modal>
    <b-row>
      <b-col md="12" lg="12" sm="12" class="review_form_spacing">
        <h4>Social Links
          <span class="educationAddIcon" @click="showWebsiteAddModal"><i class="fa-solid fa-circle-plus"></i></span>
        </h4>
        <hr class="w-100 theme_color"/>

        <!-- Social Links List -->
        <div class="iq-card col-12 col-lg-12" v-if="vmSocialLinks && Object.keys(vmSocialLinks).length > 0">
          <i class="fa fa-pencil-square pointer primary-color fa-lg m-3 pull-right" title="Edit" @click="showSocialLinkEditBox()"></i>
          <div class="iq-card-body v-else">
            <div class="mb-4" v-if="vmSocialLinks">
              <div class="row" v-for="(item, index) in vmSocialLinks" :key="index+'1'">
                <div class="col-3 capitalize pr-0 mb-3">
                  <h6 >{{index}}</h6>
                </div>
                <div class="col-9" :key="index+'1'">
                  <p class="mb-0" v-if="!editLinks">
                    <a :href="item" target="_blank">{{item}}</a>
                  </p>
                  <div v-else>
                    <input style="border:1px solid red" class="form-control" type="text" v-model="vmSocialLinks.linkedin">
                    <b-badge style="color:white !important;background:#e5252c;" class="tags mr-2 mb-2 font-weight-normal primary-color pointer pull-right mt-2" variant="none" @click="editSocialLinks">
                      Save
                    </b-badge>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!-- Social Links List -->

        <!-- Certificate Links to be added in future -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import websiteAndLinks from "../../../components/websiteAndLinks.vue"
import { SocialLinks } from "../../../FackApi/api/socialLink"

export default {
  name: "ResumeLinks",
  data () {
    return {
      showToast: false,
      toastMsg: "",
      toastVariant: "default",
      toastTitle: "Resume Links",
      cvUserId: null,
      vmSocialLinks: null,
      vmSocialLinksCopy: null,
      module_id: null,
      editLinks: false,
      showWesiteAndLinkModal: true,
      showModalWebSite: false,
      propSocialLinks: null,
      cvAddModalHeader: "Website And Social Links"
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    this.cvUserId = this.userData.user_id
    this.socialLinkView()
  },
  components: {
    websiteAndLinks
  },
  methods: {
    /**
     * socialLink
     */
    async socialLinkView () {
      try {
        const socilLinkViewResp = await SocialLinks.socialLinkView(this, this.cvUserId)
        if (!socilLinkViewResp.resp_status) {
          return
        }
        if (socilLinkViewResp.resp_data.data.social_links) {
          this.module_id = socilLinkViewResp.resp_data.data.module_id
          this.vmSocialLinks = JSON.parse(socilLinkViewResp.resp_data.data.social_links)
          this.vmSocialLinksCopy = this.vmSocialLinks

          // show only linkedin url for resume
          let linkToInclude = ["linkedin"]
          let socialLinksObj = {}

          for (let link of linkToInclude) {
            socialLinksObj[link] = this.vmSocialLinks[link]
          }

          this.vmSocialLinks = socialLinksObj
          this.propSocialLinks = this.vmSocialLinksCopy
        }
      }
      catch (error) {
        console.error("Exception occurred at userView() and Exception:", error.message)
      }
    },
    /**
     * showWebsiteAddModal
     */
    showWebsiteAddModal () {
      this.showModalWebSite = true
    },
    /**
     * closeWebsite_Links
     */
    async closeWebsite_Links () {
      await this.socialLinkView()
      // rebuild the resume
      this.$emit("emitRebuildUserSocialLinks")

      this.showModalWebSite = false
    },
    /**
     * showSocialLinkEditBox
     */
    showSocialLinkEditBox () {
      this.editLinks = !this.editLinks
    },
    /**
     * editSocialLinks
     */
    async editSocialLinks () {
      try {
        const socialLinksObj = { ...this.vmSocialLinksCopy, ...this.vmSocialLinks }
        let editObj = {
          module_id: this.module_id,
          social_links: socialLinksObj
        }
        const socialLinksEditResp = await SocialLinks.socialLinkEdit(this, editObj)
        if (socialLinksEditResp.resp_status) {
          // rebuild the resume
          this.$emit("emitRebuildUserSocialLinks")
          this.editLinks = false
        }
      }
      catch (err) {
        console.error("Exception in editSocialLinks and err: ", err)
      }
    }
  }
}
</script>

<style>
  .educationAddIcon {
    float: right;
    color: var(--iq-primary);
    cursor: pointer;
  }
</style>
