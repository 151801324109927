<template>
  <div class="row w-100 ml-0 mt-0" v-if="propExcObjList && Object.keys(propExcObjList).length > 0">
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
    <template v-for="(edu_id) of Object.keys(propExcObjList)">
      <template v-for="(exc, index) of propExcObjList[edu_id]">
        <div class="col-12 ml-0 pl-0 mb-4" :key="exc.exc_id+index">
          <div>
            <!-- {{exc.exc_type}} -->
            <span class="capitalize">
              <i class="fa fa-bullseye mr-2 primary-color pointer"></i>
              [{{exc.course_name}}th standard]
              <div class="primary-color pointer pull-right">
                <i @click="userResumeEnhance(exc.resume_section, exc)" v-if="userIsGideplus" class="fa fa-magic fa-lg" title="GIDE AI Enhancer" aria-hidden="true"></i>
                <i class="fa fa-pencil-square fa-lg ml-3" title="Edit" @click="showEditExcBox(exc, index)"></i>
                <i class="fa fa-plus-square fa-lg ml-3" title="Add" @click="showAddExcBox(exc.usp_edu_id)"></i>
              </div>
              <br/>
            <i v-if="excEditObj.exc_id == exc.exc_id" :class="{'hideEditBoxes': !(excEditObj.exc_id == exc.exc_id)}">
              <input type="text" v-model="excEditObj.exc_title" :class="{'hideEditBoxes': (excEditObj.exc_id == exc.exc_id), 'form-control': true}" :id="'exc_title'+index">
            </i>
            <i v-else :class="{'hideEditBoxes': (excEditObj.exc_id == exc.exc_id)}">
              {{exc.exc_title}}
            </i>
            </span>
            <div v-if="(excEditObj.exc_id == exc.exc_id)">
              <ValidationProvider name="Descritpion" rules='required|min:50|max:500' v-slot="{ errors }">
                <textarea autogrow v-model="excEditObj.exc_desc" style="height:200px"  type="textarea" :class="{'hideEditBoxes': (excEditObj.exc_id == exc.exc_id), 'form-control': true}" :id="'exc_desc'+index">
                </textarea>
                <span class="text-danger">{{ errors[0] }}</span>
                <b-badge style="color:white !important;background:#e5252c;" class="tags mr-2 mb-2 font-weight-normal primary-color pointer pull-right mt-2" variant="none" @click="excEdit">
                  Save
                </b-badge>
              </ValidationProvider>
            </div>
            <div v-else :class="{'hideEditBoxes': (excEditObj.exc_id == exc.exc_id)}">
              {{exc.exc_desc}}
            </div>
            <div v-if="exc.exc_desc_ai && exc.exc_desc_ai != 'NULL'" style="color:red;position:relative" class="ai-desc">
              {{exc.exc_desc_ai}}
              <i @click="replaceAiResp(exc)" class="fa fa-check-circle-o fa-lg pointer success-color" title="Apply" style="position:absolute;bottom:10px;right:10px;color:black" aria-hidden="true"></i>
            </div>
          </div>
          <div class="mt-2">
            <b-badge :style="setTagStyles(exc, 'EXP')" class="tags border mr-2 mb-2 font-weight-normal" variant="none" @click="handleTagClick('EXP', exc)">
              Experiences
            </b-badge>
            <b-badge :style="setTagStyles(exc, 'A&H')" class="tags border mr-2 mb-2 font-weight-normal" variant="none" @click="handleTagClick('A&H', exc)">
              Awards and Honours
            </b-badge>
            <b-badge :style="setTagStyles(exc, 'C&W')" class="tags border mr-2 mb-2 font-weight-normal" variant="none" @click="handleTagClick('C&W', exc)">
              Courses and Workshops
            </b-badge>
          </div>
          <template v-if="exc.exc_video_link && exc.exc_video_link != '[]' && exc.exc_video_link.length">
            <div class="row col-12 mt-2">
              <p class="mb-2 p-0 w-100 pl-4" v-if="videoLinks">- {{videoLinks}}</p>
              <div class="row w-100 pl-3">
                <template v-for="(video, index) of  parseToJSON(exc.exc_video_link)">
                  <div v-if="video.image" class="metaPreviewCol pointer" :key="index+'imageMeta'" :title="video.title">
                    <img :src="video.image" @click="openFile(video.url)">
                  </div>
                  <div v-else class="metaPreviewCol pointer text-center" :key="index+'imageMeta'" :title="video.title">
                    <i class="fa-solid fa-video-camera noVideoLinkIcon"></i>
                  </div>
                </template>
              </div>
            </div>
          </template>

          <div class="row col-12 mt-2" v-if="exc.exc_attach && exc.exc_attach != '[]' && exc.exc_attach.length">
            <p class="mb-2 p-0 w-100">- {{showcaseAttachments}}</p>
            <div class="row w-100 pl-4">
              <template v-for="(attachment, index) of parseToJSON(exc.exc_attach)">
                <div class="attachmentCol pointer" :key="index+'EXTattachmentsList'">
                  <img v-if="attachment.content && attachment.content.includes('pdf') || attachment.type && attachment.type.includes('pdf')" :src="pdfLogo" @click="openFile(attachment.url)" :title="getAttachmentName(attachment.url)">
                  <div v-else :src="attachment.url" @click="openFile(attachment.url)" :title="getAttachmentName(attachment.url)">
                    <i class="fa-solid fa-file-text attachmentCol pointer"></i>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { UserResume } from "../../../FackApi/api/UserResume"
import { ValidationProvider } from "vee-validate"
import { UserProfileExc } from "../../../FackApi/api/userProfileExc"
import pdfLogo from "../../../assets_gide/img/logo/pdfLogo.png"

export default {
  name: "ResumeExtracurricular",
  components: {
    ValidationProvider
  },
  data () {
    return {
      includeExecInResume: [],
      excludeExecFromResume: [],
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Resume Extracurriculars",
      excEditObj: {
        exc_id: null,
        exc_title: null,
        exc_desc: null
      },
      pdfLogo: pdfLogo,
      userIsGideplus: null,
      videoLinks: "Video Links (Projects, Case Studies, Achivements, Media Coverage)",
      showcaseAttachments: "Document Showcase (Only PDFs are acceptable, size less than 5mb)"
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    },
    gidePlusData () {
      return this.$store.getters["Paygw/myPlanState"]
    }
  },
  props: {
    propExcObjList: {
      type: Object,
      default: null
    }
  },
  async mounted () {
    this.userIsGideplus = await this.gidePlusData
  },
  methods: {
    /**
     * handleTagClick
     */
    handleTagClick (section, exc) {
      if (exc.resume_section != section) {
        this.addInResume(section, exc.resume_section, exc)
      }
      else {
        this.removeFromResume(section, exc.resume_section, exc)
      }
    },
    /**
     * addInResume
     */
    async addInResume (section, prevSection, exc) {
      // event.target.style = "cursor: pointer;background-color: #109618;color: white;"
      try {
        this.includeExecInResume.push(exc.exc_id)
        const userResumeAddExcResp = await UserResume.user_resume_add_exc(this, this.includeExecInResume, section)
        if (userResumeAddExcResp.resp_status) {
          this.includeExecInResume = []
          this.$emit("emitRefreshUserProfile", section, prevSection, exc, true)
        }
      }
      catch (err) {
        console.error("Exception in addInResume and error: ", err)
      }
    },
    /**
     * removeFromResume
     */
    async removeFromResume (section, prevSection, exc) {
      try {
        this.excludeExecFromResume.push(exc.exc_id)
        const userResumeRemoveExcResp = await UserResume.user_resume_remove_exc(this, this.excludeExecFromResume)
        if (userResumeRemoveExcResp.resp_status) {
          this.excludeExecFromResume = []
          this.$emit("emitRefreshUserProfile", section, prevSection, exc, false)
        }
      }
      catch (err) {
        console.error("Exception in removeFromResume and error: ", err)
      }
    },
    /**
     * setTagStyles
     */
    setTagStyles (exc, section) {
      let styles = "cursor:pointer;"
      if (exc.resume_section == section) {
        styles += "background-color:#109618;color:white;"
      }
      return styles
    },
    /**
     * parseToJSON
     */
    parseToJSON (obj) {
      if (typeof obj === "string") {
        obj = JSON.parse(obj)
      }
      return obj
    },
    /**
     * getAttachmentName
     */
    getAttachmentName (url) {
      return url.split("/").pop()
    },
    /**
     * openFile
     */
    openFile (url) {
      if (url === "") {
        // empty url means that univ admin has not accepted the application
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "This document can only be viewed after accepting the application"
        return
      }

      window.open(url, "_blank")
    },
    /**
     * userResumeEnhance
     */
    async userResumeEnhance (section, exc) {
      if (!section || section == "NULL") {
        // promt user to tag the exc for better ai enhancement
        this.toastMsg = "Tag the extracurricular for better A.I. enhancement"
        this.toastVariant = "warning"
        this.showToast = true
        return
      }
      let sectionToEnhance = null
      switch (section) {
        case "EXP":
          sectionToEnhance = {
            resume_section: section,
            exc_id: exc.exc_id,
            role: exc.exc_title,
            org: exc.exc_org,
            achievements: [exc.exc_desc]
          }
          break
        case "A&H":
          sectionToEnhance = {
            resume_section: section,
            exc_id: exc.exc_id,
            title: exc.exc_title,
            org: exc.exc_org,
            details: [exc.exc_desc]
          }
          break
        case "C&W":
          sectionToEnhance = {
            resume_section: section,
            exc_id: exc.exc_id,
            title: exc.exc_title,
            org: exc.exc_org,
            details: [exc.exc_desc]
          }
          break
      }
      // call chatgpt service to enhance the resume section
      this.sendToChatgpt(sectionToEnhance)
    },
    /**
     * sendToChatgpt
     */
    async sendToChatgpt (sectionToEnhance) {
      let enhancedResumeSection = await UserResume.user_resume_enhance(this, sectionToEnhance)
      if (enhancedResumeSection.resp_status) {
        this.$emit("emitRefreshUserProfile")
      }
    },
    /**
     * replaceAiResp
     */
    async replaceAiResp (excObj) {
      try {
        const replaceAi = await UserResume.user_resume_replace_ai_resp(this, excObj)
        if (replaceAi.resp_status) {
          await this.$emit("emitRefreshUserProfile")
          this.$emit("emitRebuildResume")
        }
      }
      catch (err) {
        console.error("Exception in replaceAiResp ex: ", err)
      }
    },
    /**
     * showAddExcBox
     */
    showAddExcBox (eduId) {
      this.$emit("emitNewExcAdded", eduId)
    },
    /**
     * showEditExcBox
     */
    async showEditExcBox (exc, excIndex) {
      if (this.excEditObj.exc_id != exc.exc_id) {
        this.excEditObj = {
          exc_id: exc.exc_id,
          exc_title: exc.exc_title,
          exc_desc: exc.exc_desc
        }
      }
      else {
        this.excEditObj = {
          exc_id: null,
          exc_title: null,
          exc_desc: null
        }
      }
    },
    /**
     * excEdit
     */
    async excEdit () {
      try {
        const excEditParams = {
          exc_id: this.excEditObj.exc_id,
          exc_title: this.excEditObj.exc_title,
          exc_desc: this.excEditObj.exc_desc
        }
        const excEditResp = await UserProfileExc.user_profile_excEdit(this, excEditParams)
        console.log("Exc Edit resp ", excEditResp)
        if (excEditResp.resp_status) {
          this.excEditObj = {
            exc_id: null,
            exc_title: null,
            exc_desc: null
          }
          this.$emit("emitRefreshUserProfile")
        }
      }
      catch (err) {
        console.error("Exception in excEdit and err: ", err)
      }
    }
  }
}
</script>

<style lang="scss">
  .tags {
    color: #109618;
    border-color: #109618 !important;
  }
  .ai-desc {
    background-color: whitesmoke;
    padding: 15px;
  }
  .hideEditBoxes {
    border: 1px solid #e5252c !important;
    margin: 10px;
  }
  .attachmentCol {
  img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    border: 2px solid aliceblue;
    margin-bottom: 6px;
  }
  }
</style>
